import React from "react";
import {
  errorCodeControl,
  userGetList,
  userPost,
} from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getAll = (
  filter,
  setfilter,
  setrowCount,
  setData,
  setIsLoading
) => {
  userPost("api/admin/Product/GetAll", filter)
    .then((a) => {
      debugger;
      try {
        if (a.data.list.length === 0 && a.data.rowsCount > 0) {
          setfilter({ ...filter, page: filter.page - 1 });
        } else {
          setData(a.data.list);
          setrowCount(a.data.rowsCount);
        }
      } catch (error) {
        setData(a.data.list);
        setrowCount(a.data.rowsCount);
      }

      setIsLoading(false);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
      setIsLoading(false);
    });
};

export const getFilter = (setData, filter, setFilter, setCategory) => {
  userGetList("api/admin/Product/GetMain")
    .then((a) => {
      setData(a.data);
      setCategory(a.data.categories);
      if (!filter.categories) {
        const processedCategories = {};

        a.data.categories.forEach((category, index) => {
          if (index > 0) {
            processedCategories["category-" + index] = [];
          }
        });

        setFilter({ ...filter, categories: processedCategories });
      } else {
        setFilter((prevFilter) => ({ ...prevFilter, ...filter }));
      }
    })
    .catch((error) => {
      debugger;
      setData([]);
      errorCodeControl(error);
    });
};
export const getProccess = (id, setdata) => {
  userGetList("api/admin/Product/GetProductProccess", { id })
    .then((a) => {
      setdata(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const getProccessPrint = (id, setdata) => {
  userGetList("api/admin/Product/GetProductProccessPrint", { id })
    .then((a) => {
      setdata(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
